import React from 'react';
import PropTypes from 'prop-types';

import Link from '../link';
import Image from '../image';

import Img from 'gatsby-image';

import './index.scss';

export const TileSection = ({
  children,
  dark,
  threeColumns,
  bigTiles,
  darkHover,
  className,
  ...props
}) => {
  return (
    <section
      className={
        'tile-section' +
        (dark ? ' dark' : '') +
        (threeColumns ? ' three-columns' : '') +
        (bigTiles ? ' big-tiles' : '') +
        (darkHover ? ' dark-hover' : '') +
        (className ? ' ' + className : '')
      }
      {...props}
    >
      {children}
    </section>
  );
};

export const Tile = ({ name, img, to, title }) => {
  return (
    <Link to={to} className="tile">
      {typeof img === 'string' || img instanceof String ? (
        <Image src={img} alt={name} />
      ) : (
        <Img
          className="image"
          imgStyle={{ transition: 'opacity 500ms ease 0s, transform 0.8s ease' }}
          fluid={img}
          alt={name}
        />
      )}
      {title && <span className="tile-title">{title}</span>}
      <span className="tile-name">{name}</span>
    </Link>
  );
};

Tile.propTypes = {
  name: PropTypes.string.isRequired,
  img: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  to: PropTypes.string.isRequired,
  title: PropTypes.string,
};

TileSection.propTypes = {
  threeColumns: PropTypes.bool,
  bigTiles: PropTypes.bool,
  children: PropTypes.node.isRequired,
  dark: PropTypes.bool,
  darkHover: PropTypes.bool,
  style: PropTypes.object,
};
