import React from 'react';

import SEO from '../../components/seo';
import Navigation from '../../components/navigation';
import { ColumnSection, Column } from '../../components/column-section';
import { TileSection, Tile } from '../../components/tile-section';

import { graphql } from 'gatsby';

import Img from 'gatsby-image';
import { Languages } from '../../tools/languages';
import { SecondaryButton } from '../../components/buttons';

const Vacancies = ({ vacancies }) => {
  return (
    <TileSection>
      {vacancies.edges.map((vacancy) => {
        const { id, slug, title, image } = vacancy.node;
        return <Tile key={id} to={slug} img={image.childImageSharp.fluid} name={title}></Tile>;
      })}
    </TileSection>
  );
};

const DutchCareersPage = ({ location, data, language = Languages.NL }) => {
  const pathName = location.pathname;

  return (
    <>
      <SEO pathName={pathName} title="Werken bij" lang={language} />
      <Navigation />
      <main>
        <h1>Werken bij Bavak</h1>
        <Img
          className="image"
          fluid={data.file.childImageSharp.fluid}
          alt="Rapiscan machine provided by Bavak Security Group"
        />
        <ColumnSection>
          <Column>
            <h3>Kom je bij ons werken?</h3>
          </Column>
          <Column>
            <p>
              Innovatie is een essentieel onderdeel van Bavak. Ons team werkt continu aan het
              ontwerpen van nieuwe beveiligingsoplossingen. Dit varieert van de ontwikkeling van de
              unieke geïntegreerde beveiligingsproducten voor vitale infrastructuur, tot de beste
              productietechnieken voor een nieuwe Speedgate. Bavak ontwikkelt, levert en onderhoudt
              wereldwijd de beveiliging voor vitale infrastructuur.
            </p>
            <p>
              Bij Bavak staat de innovatie van cyber-elektronische en fysieke beveiliging voorop.
              Ook draagt Bavak direct zorg voor de juiste implementatie van deze security
              oplossingen. Daarnaast levert Bavak zeer gespecialiseerde gebruikerstrainingen en het
              correctieve maar ook preventieve onderhoud van de apparatuur op de lange termijn. Al
              meer dan 45 jaar verzorgt Bavak de beveiliging voor de meest uiteenlopende klanten en
              dit wordt mogelijk gemaakt door te werken met hoog gekwalificeerd personeel, de beste
              service voor alle beveiligingsapparatuur en absolute discretie.
            </p>
          </Column>
        </ColumnSection>
        <Vacancies vacancies={data.vacancies} />
        <ColumnSection dark>
          <Column>
            <div>
              <h3>Heb je vragen over onze vacatures?</h3>
              <p>
                Ben je benieuwd hoe ons proces werkt of wil je meer informatie? Neem contact met ons
                op!
              </p>
            </div>
            <div>
              <SecondaryButton to="/contact/">Neem contact op</SecondaryButton>
            </div>
          </Column>
        </ColumnSection>
      </main>
    </>
  );
};

export const query = graphql`
  {
    file(relativePath: { eq: "placeholder.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vacancies: allStrapiVacancies {
      edges {
        node {
          id
          slug
          image {
            childImageSharp {
              fluid(maxWidth: 960, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title: title_nl
        }
      }
    }
  }
`;

export default DutchCareersPage;
