import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const Image = ({ isFullScreen, src, alt = '' }) => {
  return isFullScreen ? (
    <div className="image" style={{ '--src': `url(${src})` }}></div>
  ) : (
    <div className="image">
      <img src={src} alt={alt} />
    </div>
  );
};

Image.propTypes = {
  isFullScreen: PropTypes.bool,
  src: PropTypes.node,
  alt: PropTypes.string,
};

Image.defaultProps = {
  isFullScreen: false,
};

export default Image;
