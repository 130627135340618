import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const SubTitle = ({ children, className, ...props }) => {
  return (
    <span className={'sub-title' + (className ? ' ' + className : '')} {...props}>
      {children}
    </span>
  );
};

SubTitle.propTypes = {
  children: PropTypes.node,
};

export default SubTitle;
