import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import SubTitle from '../sub-title';

import './index.scss';

export const ColumnSection = ({
  children,
  dark,
  indicator,
  centerVertically,
  className,
  noPadding,
  ...props
}) => {
  return (
    <section
      className={
        'column-section' +
        (dark ? ' dark' : '') +
        (centerVertically ? ' center-vertically' : '') +
        (indicator ? ' indicator' : '') +
        (noPadding ? ' no-padding' : '') +
        (className ? ' ' + className : '')
      }
      {...props}
    >
      {children}
    </section>
  );
};

export const RelatedNewsItem = ({ to, title, time }) => {
  return (
    <Link to={to}>
      <li>
        <p>{title}</p>
        <SubTitle>{time}</SubTitle>
      </li>
    </Link>
  );
};

export const RelatedNews = ({ title, children }) => {
  return (
    <div className="column related-news">
      <SubTitle>{title}</SubTitle>
      <ul>{children}</ul>
    </div>
  );
};

export const Column = ({ children, isServiceColumn }) => {
  return isServiceColumn ? (
    <div className="column service-column">{children}</div>
  ) : (
    <div className="column">{children}</div>
  );
};

RelatedNews.propTypes = {
  title: PropTypes.string,
};

RelatedNews.defaultProps = {
  title: 'Recent News',
};

ColumnSection.propTypes = {
  dark: PropTypes.bool,
  indicator: PropTypes.bool,
  noPadding: PropTypes.bool,
  centerVertically: PropTypes.bool,
};

Column.propTypes = {};
